<template>
    <div>
        <ApexCharts
            class="apexchart"
            :options="mergedOptions"
            :series="series"
            :height="height"
            :width="width"
            type="donut"
            />
    </div>
</template>

<script>

import { isObject } from "lodash";

export default {
    name: "GraphDonut",
    components: {
        ApexCharts: () => import('vue-apexcharts'),
    },
    props: {
        series: {
            type: Array,
            required: true
        },
        options:{
            type: Object,
            default: () => {return {}}
        },
        height: {
            type: String || Number,
            default: 'auto'
        },
        width: {
            type: String || Number,
            default: '100%'
        }
    },
    data() {
        return {
            defaultOptions: {
                defaultLocale: 'pt-BR',
                foreColor: '#52526B',
                chart: {
                    type: "donut"
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    fontSize: '16px',
                    fontFamily: 'Roboto',
                    itemMargin: {
                        vertical: 4,
                    },
                    labels: {
                        colors: '#52526B'
                    },
                    markers: {
                        offsetX: -4,
                    }
                },
                stroke: {
                    show: false
                },
                tooltip: {
                    enabled: false
                }
            }
        }
    },

    computed: {
        mergedOptions() {
            return this.mergeDeep(this.defaultOptions, this.options);
        }
    },
    methods: {
        mergeDeep(obj1, obj2) {
            var output = Object.assign({}, obj1);
            
            for (var key in obj2) {
                if (isObject(obj2[key])) {
                    if (!(key in obj1))
                        Object.assign(output, { [key]: obj2[key] });
                    else
                        output[key] = this.mergeDeep(obj1[key], obj2[key]);
                } else {
                    Object.assign(output, { [key]: obj2[key] });
                }
            }
            
            return output;
        }
    }

}

</script>

<style lang="scss" scoped>
</style>